<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import {
    getCampaignDays,
    convertToDateFromString,
    parseDayFromString,
  } from "../utils";

  import { getFragmentConfigOr } from "~/components/field-utils";
  import { ANY_DAY, filter_by_day } from "../store";
  import { propsToCustomProperties } from "~/util/style";
  import Dropdown from "./Dropdown.svelte";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;

  const stores_context = getStoresContext(request_store_key);
  const { locale, T, offers_ext } = stores_context;

  type Config = {
    use_new_layout: boolean,
    day_filter_color: string;
    day_filter_bg_color: string;
    day_filter_active_color: string;
    day_filter_active_bg_color: string;
  }

  const config: Config = {
    use_new_layout: 
      getFragmentConfigOr("useNewLayout", fragment, false),
    day_filter_color: 
      getFragmentConfigOr("textColor", fragment, "var(--brandColor1)"),
    day_filter_bg_color: 
      getFragmentConfigOr("backgroundColor", fragment, "var(--brandColor2)"),
    day_filter_active_color: 
      getFragmentConfigOr("activeTextColor", fragment, "var(--brandColor2)"),
    day_filter_active_bg_color: 
      getFragmentConfigOr("activeBackgroundColor", fragment, "var(--brandColor1)")
  }

  $: ({ items } = $offers_ext);

  $: dayFilters = items && getCampaignDays(items);
  $: t = $T;

  function action(day: string) {
    filter_by_day.set(day);
  }

</script>

{#if $locale && items && items.length}
  <div
    class="day-filter-container old-layout" 
    style={`
      --day_filter_color: ${config.day_filter_color};
      --day_filter_bg_color: ${config.day_filter_bg_color};
      --day_filter_active_color: ${config.day_filter_active_color};
      --day_filter_active_bg_color: ${config.day_filter_active_bg_color};
    `}>
    {#if config && config.use_new_layout}
      <Dropdown color={config.day_filter_active_color} backgroundColor={config.day_filter_bg_color}>
        <svelte:fragment slot="dropDownTitle">
            {$filter_by_day == ANY_DAY ? t`offers.allDays` : parseDayFromString($filter_by_day, $locale, "long") + " " + convertToDateFromString($filter_by_day, $locale)}
        </svelte:fragment>
        <svelte:fragment slot="resetFilter">
          <div 
            class="dropdown-item"
            class:active={$filter_by_day == ANY_DAY}
          >
            <button 
              class="dropdown-item-button" 
              on:click={() => action(ANY_DAY)}
            >
              {t`offers.allDays`}
            </button>
          </div>
        </svelte:fragment>
        <svelte:fragment slot="dropDownItems">
          {#if dayFilters}
            {#each dayFilters as day}
              <div 
                class="dropdown-item"
                class:active={$filter_by_day == day}
              >
                <button 
                  class="dropdown-item-button" 
                  on:click={() => action(day)}
                >
                  {$locale && parseDayFromString(day, $locale, "long")}
                  {convertToDateFromString(day, $locale)}
                </button>
              </div>
            {/each}
          {/if}
        </svelte:fragment>
      </Dropdown>
    {:else}
      <div
        class="day-filters d-md-flex justify-content-center d-none d-md-block w-100 mb-4">
        <button
          class:active={$filter_by_day == ANY_DAY}
          on:click={() => action(ANY_DAY)}>
          {t`offers.allDays`}
        </button>
        {#if dayFilters}
          {#each dayFilters as day}
            <button
              data-date={day}
              class:active={$filter_by_day == day}
              on:click={() => action(day)}>
              {$locale && parseDayFromString(day, $locale, "long")}
              {convertToDateFromString(day, $locale)}
            </button>
          {/each}
        {/if}
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  .day-filter-container {
    font-family: 'KHGrotesk', sans-serif;
    font-size: 16px;
    min-width: 200px;
    width: 100%;
    margin-right: 1rem;
  }
  .dropdown-item-button{
    display: block;
    width: 100%;
    background: none;
    border: none;
  }
  .dropdown-item {
    border-bottom: 1px solid var(--brandColor1);
    padding: 5px 10px;
    cursor: pointer;
    button{
      color: var(--brandColor1);
      text-align: left;
      text-transform: capitalize;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &.active {
      background-color: var(--brandColor1);
      button {
        color: #fff !important;
      }
    }
  }

  .old-layout {
    .day-filters {
      flex-wrap: wrap;
      button {
        padding: 10px;
        border: none;
        color: var(--day_filter_color);
        background-color: var(--day_filter_bg_color);
        font-weight: 600;
        text-transform: uppercase;
      }
      button.active,
      button:hover {
        color: var(--day_filter_active_color);
        background-color: var(--day_filter_active_bg_color);
        text-decoration: underline;
      }
      @media only screen and (max-width: 576px) {
        button {
          width: 90vw;
          margin: 2px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .day-filter-container {
      min-width: 0;
      width: 45vw;
      margin-right: 10px;
    }
  }
</style>
